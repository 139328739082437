import './App.css';
import {useState} from "react";
import {
  Button,
  Box,
  FormControl,
  TextField, Container, ThemeProvider,
} from "@mui/material";
import * as EmailValidator from 'email-validator';
import RocketIcon from '@mui/icons-material/RocketLaunchOutlined';
import theme from "./theme";

const App = () => (
  <ThemeProvider theme={theme}>
    <Container maxWidth="lg" disableGutters>
      <div className="App">
        <header className="App-header">
          <div className="App-logo-container">
            <img src={'https://d35hqxgi39s4oq.cloudfront.net/images/logo192.png'} className="App-logo" alt="logo"/>
          </div>
          <h1 className="App-title">
            <span style={{color: '#00ffbb'}}>ZEN</span><span style={{color: '#de00c0'}}>VESTIA</span>
          </h1>
          <p style={{padding: 0, margin: 0, fontWeight: 300}}>
            <span style={{fontWeight: 500}}>
              Your
            </span> investment portfolio made
            <span style={{fontWeight: 500}}> Smarter</span>
          </p>
          <Box sx={{color: '#00ffbb', display: 'flex', alignItems: 'center', marginTop: '4rem', fontWeight: 'normal'}}>
            <span style={{fontSize: '1.25rem'}}>
              Launching soon
            </span>
            <RocketIcon sx={{ml: 1, mb: 1, color: '#00ffbb'}}/>
          </Box>
          <EarlyAccessForm/>
        </header>
        <footer className="App-footer"><p>© 2024 Cosmagical Limited, All rights reserved.</p></footer>
      </div>
    </Container>
  </ThemeProvider>
);

const EarlyAccessForm = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [email, setEmail] = useState("");

  const sendEmail = (e) => {
    const endpoint = "https://s7fjhcfyxpxx66nwt2kxvzgw7i0baxkc.lambda-url.us-east-1.on.aws/"
    const body = JSON.stringify({senderName: "", senderEmail: email, message: ""});
    const requestOptions = {
      method: "POST",
      headers: { "Accept": "application/json" },
      body,
    };

    setEmail("");
    setIsRegistered(false);

    fetch(endpoint, requestOptions)
      .then(response => response.json())
      .then(res => console.log(res))
      .catch(err => console.log(err))
      .finally(() => {
      })
  };

    const handleOnChange = (e) => {
      setEmail(e.target.value);
    }

    return (
      <Box sx={{pt:6, display: 'flex', flexDirection: 'column'}}>
        <FormControl sx={{minWidth: "24rem", position: 'relative'}} onSubmit={sendEmail} onChange={handleOnChange}>
          <TextField
            autoFocus
            placeholder="Enter your email"
            type='email'
            sx={{backgroundColor: '#fafafafa', color: '#03001F', borderRadius: "0.25rem"}}
          />
          <Button
            variant='contained'
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              width: 88,
              textTransform: 'none',
              fontWeight: 500,
              backgroundColor: '#00ffbb',
              color: '#03001F'
            }}
            onClick={sendEmail}
            disabled={!EmailValidator.validate(email)}
          >
            Register
          </Button>
        </FormControl>
        <span style={{ marginTop: '1rem', fontSize: '1rem'}}>Register now for free exclusive early access!</span>
        {isRegistered &&
          <Box sx={{marginTop: '1rem'}}>
            <span
              style={{
                padding: "0 0.5rem",
                fontSize: '1rem',
                fontWeight: 'normal',
                color: '#03001F',
                backgroundColor: '#00ffbb',
                borderRadius: '0.5rem'
              }}
            >
              Congrats, you are registered for early access !
            </span>
          </Box>
        }
      </Box>
    )
}


export default App;