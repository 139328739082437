import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'Nunito', 'Roboto','Montserrat', 'serif'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "rgba(78,133,101,0.37)",
            color: "#7d7d7e"
          }
        }
      }
    }
  },
});

export default theme;